<template>


    <button @click="navApp" class="back__btn">
        <svg width="81" height="42" viewBox="0 0 81 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21" cy="21" r="21"/>
            <path
                    d="M27.2642 15.75L20.3951 19.6786C20.3951 19.6786 19.3105 20.2471 19.3105 20.75C19.3105 21.2529 20.3951 21.8214 20.3951 21.8214L27.2642 25.75"
                    stroke-width="2.7" stroke-linecap="round"/>
            <path d="M21.4655 20.7498H79.3106" stroke-width="2.7" stroke-linecap="round"/>
        </svg>
    </button>
    <div class="content top__30px">
        <!-- <div class="content__img">
            <img class="img-fluid image-border" src="../assets/images/logo-app.png" alt="">
        </div> -->
        <form class="form" @submit.prevent="signup">
            <div class="form__header">
                <div class="form__title">{{ formTitle }}</div>
                <div class="form__title">{{ nebo }}</div>
            </div>
            <div class="form__fields">

                <!-- данные исполнителя начало -->
                <fieldset class="form__fieldset">
                    <legend class="form__legend">Данные исполнителя номера</legend>
                    <div class="fieldset-list">
                        <div class="fieldset-list__item">
                            <div class="form__group">
                                <input type="text" class="form__input" placeholder="Введите Фамилию участника"
                                       v-model="v$.artistSurname.$model">
                                <label class="form__label">Фамилия</label>
                            </div>
                            <p v-for="(error, index) in v$.artistSurname.$errors" :key="index"
                               class="error-container form__error">{{
                                error.$message
                                }}</p>

                            <ui-list v-if="!hiddenList && v$.artistSurname.$model !== ''">
                                <ui-item v-for="item in userHistories" :key="item" @click="setData(item)">
                                    <ui-item-text-content>{{
                                        item.user.surname + ' ' + item.user.name + ' ' +
                                        item.user.patronymic
                                        }}
                                    </ui-item-text-content>
                                </ui-item>
                            </ui-list>

                            <div class="form__group">
                                <input type="text" class="form__input" placeholder="Введите Имя участника"
                                       v-model="v$.artistName.$model">
                                <label class="form__label">Имя</label>
                            </div>
                            <p v-for="(error, index) in v$.artistName.$errors" :key="index"
                               class="error-container form__error">{{
                                error.$message
                                }}</p>

                            <div class="form__group">
                                <input type="text" class="form__input" placeholder="Введите Отчество участника"
                                       v-model="v$.artistLastname.$model">
                                <label class="form__label">Отчество</label>
                            </div>
                            <p v-for="(error, index) in v$.artistLastname.$errors" :key="index"
                               class="error-container form__error">{{
                                error.$message
                                }}</p>

                            <div class="form__group">
                                <input type="date" min="1960-06-01" max="2070-06-01" placeholder="дд.мм.гггг"
                                       class="form__input"
                                       v-model="v$.artistBirthday.$model">
                                <label class="form__label">Дата рождения</label>
                            </div>
                            <p v-for="(error, index) in v$.artistBirthday.$errors" :key="index"
                               class="error-container form__error">{{
                                error.$message
                                }}</p>

                            <div class="form__group">
                                <input type="text" class="form__input" placeholder="Укажите город проживания"
                                       v-model="v$.city.$model">
                                <label class="form__label">Город проживания</label>
                            </div>
                            <p v-for="(error, index) in v$.city.$errors" :key="index"
                               class="error-container form__error">{{
                                error.$message
                                }}</p>


                        </div>
                    </div>
                </fieldset>

                <!-- Город выступления -->
                <selectPerformanceCity v-model="performanceCity"/>

                <!-- Уровень подготовки -->
                <selectLevel v-model="level"/>

                <!-- Номинация -->
                <selectNomination v-model="nomination"/>

                <!-- Снаряд -->
                <projectileDataForm @getProjectile="getProjectile"/>

                <!-- Соло/дуэт -->
                <soloOrDuetForm @getPerformanceType="getPerformanceType"/>

                <!-- Данные партера по дуэту form -->
                <dataPartnerDuetForm :performanceType="performanceType" v-if="performanceType === 'дуэт'"
                                     @getPartnerName="getPartnerName" @getPartnerSurname="getPartnerSurname"
                                     @getPartnerLastname="getPartnerLastname"/>

                <!-- Командное участие select -->
                <commandSelect @getCommandSelect="getCommandSelect"/>

                <!-- командное участие форма -->
                <!-- <schoolFormSearch v-if="command" :command="command" @getSchool="getSchool"
                    @getCustomSchool="getCustomSchool" /> -->
                <!-- <CustomInput v-model="searchText" /> -->

                <!-- поиск школ -->

                <fieldset v-if="this.command" class="form__fieldset">
                    <legend class="form__legend">Школа</legend>
                    <div class="form__group">
                        <input type="text" class="form__input" v-model="school">
                        <label class="form__label">Школа</label>
                    </div>
                    <p v-if="showErr1" class="error-container form__error">Поле "Школа" обязательно для заполнения</p>
                    <ui-list v-if="school.length > 2">
                        <ui-item @click="setSchool(item)" v-for="item in filterSchool" :key="item">
                            <ui-item-text-content>{{ item.title }}</ui-item-text-content>
                        </ui-item>
                    </ui-list>
                </fieldset>

                <fieldset class="form__fieldset">
                    <legend class="form__legend">Тренер</legend>
                    <div class="form__group">
                        <input type="text" class="form__input" v-model="coach" placeholder="Введите ФИО тренера"/>
                        <label class="form__label">Тренер</label>
                    </div>
                    <p v-if="showErr2" class="error-container form__error">Поле "Тренер" обязательно для заполнения</p>
                    <ui-list v-if="coach.length > 2">
                        <ui-item v-for="item in filterCoaches" :key="item" @click="getCoach(item)">
                            <ui-item-text-content>{{ item.surname ?? '' }} {{ item.name ?? '' }}
                            </ui-item-text-content>
                        </ui-item>
                    </ui-list>
                </fieldset>
                <!-- поиск тренеров -->

                <!-- данные для связи форма начало -->
                <fieldset class="form__fieldset">
                    <legend class="form__legend">Данные для связи</legend>
                    <div class="fieldset-list__item">

                        <div class="form__group">
                            <input @click="clearPhone" @input="formatPhone" type="text" class="form__input"
                                   v-model="v$.accompanyingPhone.$model" placeholder="Введите телефон без кода страны"
                                   style="display: inline-block;"><img src="../assets/images/phone.svg"
                                                                       style="display: flex; float: right; margin-top: -30px;"/>
                            <label class="form__label">Телефон</label>
                        </div>

                        <p v-for="(error, index) in v$.accompanyingPhone.$errors" :key="index"
                           class="error-container form__error">{{
                            error.$message
                            }}</p>
                        <div class="form__group">
                            <input type="text" class="form__input" v-model="v$.email.$model"
                                   placeholder="Введите email"><img src="../assets/images/mail.svg"
                                                                    style="display: flex; float: right; margin-top: -30px;"/>
                            <label class="form__label">E-mail</label>
                        </div>
                        <p v-for="(error, index) in v$.email.$errors" :key="index" class="error-container form__error">
                            {{
                            error.$message
                            }}
                        </p>
                    </div>

                    <div>
                        <label style="font-size: 18px; margin-top: 30px; color: #ff0000;"><b>Перед отправкой заявки,
                            необходимо проверить корректность введенных
                            данных</b></label>
                    </div>
                </fieldset>

            </div>
            <div class="form__footer">
                <!-- соглашение с обработкой персональных данных -->
                <confirmationDataChecked @getConfirmationData="getConfirmationData"/>
                <div class="form__btn">
                    <button type="submit" class="action-button" :disabled="buttonDisabled">Регистрация</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import selectLevel from '@/components/Radio/SelectLevel.vue';
import selectNomination from '@/components/Radio/SelectNomination.vue';
import projectileDataForm from '@/components/Selected/Radio/ProjectileDataForm.vue';
import soloOrDuetForm from '@/components/Selected/Radio/SoloOrDuetForm.vue';
import dataPartnerDuetForm from '@/components/Input/DataPartnerDuetForm.vue';
import commandSelect from '@/components/Selected/Radio/CommandSelect.vue';
import confirmationDataChecked from '@/components/Checked/ConfirmationDataChecked.vue';
import {useVuelidate} from '@vuelidate/core';
import {email, helpers, minLength, required} from '@vuelidate/validators';
import SelectPerformanceCity from "@/components/Selected/Radio/SelectPerformanceCity.vue";

const formTitle = 'Регистрация на Фестиваль воздушного искусства'
const nebo = '"Малиновое небо"'

export default {
    name: 'SignupNumberView',
    components: {
      SelectPerformanceCity,
        selectLevel,
        selectNomination,
        projectileDataForm,
        soloOrDuetForm,
        dataPartnerDuetForm,
        commandSelect,
        confirmationDataChecked,
    },
    data() {
        return {
            showErr1: false,
            showErr2: false,
            searchText: '',
            school: '',
            buttonDisabled: false,
            formTitle,
            nebo,
            performanceCity: '',
            artistSurname: "",
            artistName: "",
            artistLastname: "",
            artistBirthday: "",
            accompanyingPhone: "",
            accompanyingFIO: "",
            city: "",
            email: "",
            hiddenList: false,
            name: "",
            surname: "",
            lastname: "",
            birthday: null,
            phone: "",
            projectile: null,
            nomination: "",
            level: "",
            age: "",
            trainerFio: "",
            fio: "",
            partnerName: "",
            partnerSurnane: "",
            partnerLastname: "",
            institution: "",
            performanceType: "",
            selectedAmmunition: "",
            command: null,
            command_name: "",
            custom_command: "",
            coach: "",
            coach_id: 0,
            team_id: "",
            confirmationData: false,
            custom_coach: "",
            custom_school: "",
            latestUserData: [],
        }
    },
    computed: {
        userHistories() {
            let self = this;
            return this.$store.state.vkUserPerformances.filter(function (item) {
                return item.user.surname.toLowerCase().includes(self.v$.artistSurname.$model.toLowerCase())
            })
        },
        filterSchool() {
            let self = this;

            return this.$store.state.schools.filter(function (item) {
                return item.title.toLowerCase().includes(self.school.toLowerCase())
            })
        },
        filterCoaches() {
            let self = this;

            return this.$store.state.coaches.filter(function (item) {

                if (!item.name) {
                    return item.surname.toLowerCase().includes(self.coach.toLowerCase()) ||
                        item.patronymic.toLowerCase().includes(self.coach.toLowerCase())
                }

                if (!item.surname) {
                    return item.name.toLowerCase().includes(self.coach.toLowerCase()) ||
                        item.patronymic.toLowerCase().includes(self.coach.toLowerCase())
                }

                if (!item.patronymic) {
                    return item.name.toLowerCase().includes(self.coach.toLowerCase()) ||
                        item.surname.toLowerCase().includes(self.coach.toLowerCase())
                }

            })
        }

    },
    validations: {
        artistSurname: {
            required: helpers.withMessage('Поле "Фамилия" обязательно для заполнения', required)
        },
        artistName: {
            required: helpers.withMessage('Поле "Имя" обязательно для заполнения', required)
        },
        artistLastname: {
            required: helpers.withMessage('Поле "Отчество" обязательно для заполнения', required)
        },
        city: {
            required: helpers.withMessage('Поле "Город" обязательно для заполнения', required)
        },
        performanceCity: {
            required: helpers.withMessage('Поле "Город" обязательно для заполнения', required)
        },
        artistBirthday: {
            required: helpers.withMessage('Поле "День рождение" обязательно для заполнения', required)
        },
        accompanyingPhone: {
            required: helpers.withMessage('Поле "Телефон" обязательно для заполнения', required),
            minLength: helpers.withMessage('Минимальное количество символов 11', minLength(11)),
        },
        email: {
            required: helpers.withMessage('Поле "Email" обязательно для заполнения', required),
            email: helpers.withMessage('Некорректный email адресс', email)
        },
    },
    watch: {
        school() {
            this.showErr1 = this.school.length < 1;
        }
    },
    setup() {
        return {v$: useVuelidate()}
    },
    methods: {
        navApp() {
            if (this.$store.state.vkUserPerformances.length > 0) {
                this.$router.push('/personal-area')
            } else {
                this.$router.push('/participant')
            }
        },
        setSchool(school) {
            this.school = school.title
        },
        getCoach(coach) {
            this.coach = coach.surname + ' ' + coach.name;
            this.coach_id = coach.id;
        },
        formatPhone() {
            let input = this.accompanyingPhone.replace(/\D/g, '').substring(0, 11); // Убираем все нецифровые символы
            input = input ? `+7${input.substring(1)}` : '+7'; // Заменяем все цифры, кроме первой, на "7"
            this.accompanyingPhone = input;
        },
        getUserPerformances() {
            // -unique
            axios.post(`${process.env.VUE_APP_URL}/api/user-performance`, {
                // vk_id: 715104636,
                vk_id: localStorage.getItem('vk_user_id'),
            })
                .then((res) => {

                    this.$store.state.vkUserPerformances = res.data

                    if (this.$store.state.permissionMessageApp) {
                        if (this.$store.state.vkUserPerformances.length > 0) this.$router.push('personal-area')
                    }

                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getProjectile(projectile) {
            this.projectile = projectile
        },
        getPerformanceType(performanceType) {
            this.performanceType = performanceType
        },
        getUserYears(date) {
            const birthDate = new Date(date);
            const diff = Date.now() - birthDate.getTime();
            return new Date(diff).getFullYear() - 1970;
        },
        getPartnerName(name) {
            this.partnerName = name
        },
        getPartnerSurname(surname) {
            this.partnerSurname = surname
        },
        getPartnerLastname(lastname) {
            this.partnerLastname = lastname
        },
        getCommandSelect(value) {
            this.command = value

            console.log('Командное или одиночное выступление: ' + value)
        },
        getConfirmationData(bool) {
            this.confirmationData = bool
            console.log('Согласен с обработкой персональных данных: ' + bool)
        },
        setData(item) {
            this.hiddenList = true
            this.artistSurname = item.user.surname
            this.artistName = item.user.name
            this.artistLastname = item.user.patronymic
            this.artistBirthday = item.user.date_of_birth
            this.accompanyingPhone = item.user.phone
            this.email = item.user.email
            this.city = item.user.city
            this.performanceCity = item.user.performanceCity
            this.accompanyingFIO = item.user.accompanying.fio
        },
        async signup() {
            this.buttonDisabled = true;
            setTimeout(() => {
                this.buttonDisabled = false;
            }, 2000);

            let command = null

            const isFormCorrect = await this.v$.$validate()

            if (!isFormCorrect) {
                this.$alert('Некоторые данные введены неверно')
                return
            }

            if (this.confirmationData) {
                let partnerFIO = `${this.partnerSurname} ${this.partnerName} ${this.partnerLastname}`
                // if (this.getUserYears(this.artistBirthday) < 18 && this.accompanyingFIO == '') return false
                if (this.soloOrDebut === 'Соло') this.partnerFio = 'одиночное выступление'
                if (this.command) command = this.command
                if (this.performanceType === 'соло') partnerFIO = ''
                if (this.getUserYears(this.artistBirthday) > 18) this.accompanyingFIO = null

                axios.post(`${process.env.VUE_APP_URL}/api/performance-create`, {
                    name: this.artistName,
                    surname: this.artistSurname,
                    patronymic: this.artistLastname,
                    city: this.city,
                    performance_city: this.performanceCity,
                    email: this.email,
                    birthday: this.artistBirthday,
                    level: this.level,
                    vk_id: localStorage.getItem('vk_user_id'),
                    accompanying_fio: this.accompanyingFIO,
                    phone: this.accompanyingPhone,
                    team_id: command,
                    school_title: this.school,
                    coach_title: this.coach,
                    coach_id: this.coach_id,
                    projectile_id: this.projectile.id,
                    solo_or_duet: this.performanceType,
                    nomination: this.nomination,
                    date_of_birth: this.artistBirthday,
                    patner_fio: partnerFIO,
                    price: 0,
                })
                    .then((res) => {
                        console.log(res);
                        this.$alert('Выступление создано');
                        this.createVkMessage()
                        this.getUserPerformances()
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$alert(err.response.data.message);
                    })
            } else {
                this.$alert('Необходимо согласие на обработку персональных данных');
            }
        },
        createVkMessage() {
            let vk_name = localStorage.getItem('name');
            let vk_user_id = localStorage.getItem('vk_user_id');
            let city = +this.performanceCity === 1 ? "Сочи" :
                +this.performanceCity === 2 ? "Санкт-Петербург" : "Город не указан";
            let message = `
                ✅ ${vk_name}, номер успешно зарегистрирован.            
                |перенос| Участник: ${this.artistSurname} ${this.artistName} ${this.artistLastname}
                |перенос| Город выступления: ${city}
                |перенос| Снаряд: ${this.projectile.title}
                |перенос| Номинация: ${this.nomination} 
                |перенос| Тип: ${this.performanceType} 
                |перенос| Вам придет уведомление, когда организатор проверит заявку.
                          После этого Вы сможете внести дополнительные данные в личном кабинете
                          и загрузить музыку для выступления
             `
            axios.get(`https://vkbot.inform-s.ru/?message=${message}&user_id=${vk_user_id}`)
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    }
}
</script>
